export enum connectionState {
    new = 0,
    cancelled = 1,
    discarded = 2,
    broken = 3,
    connected = 9
}

export const noob = "noob"

export const noobRegex = /^noob/i
// export const noobRegex = /^noob[0-9]+$/i
