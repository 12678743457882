const l = console.log.bind(console)
const $ = document.getElementById.bind(document)
const $$ = document.querySelector.bind(document)

import Qrious from '../node_modules/qrious/dist/qrious.js'
import { connectionState, noobRegex } from "./universal.ts"

interface User { id: number, name: string, bio: string, interests: number[] }
interface Connection { from_user_id: number, to_user_id: number, from_timestamp: number, to_timestamp: number, state: number, note: string }
interface Data { user: User, connections: Connection[], users: { [index: number]: User }, interests: number[] }

enum pageLocation {
    dropdown = 1,
    top = 2,
    none = 3
}

let main, data: Data

const page_handlers: [string, string, pageLocation, (arg?: string) => void][] = []

window.onload = async () => {
    console.log("---------------------------------------")
    window.addEventListener('hashchange', function () {
        // route()
    })

    window.addEventListener("popstate", function (event) {
        if (event.state) {
            l("popstate with state", event.state)
        } else {
            l("popstate no state", event)
        }
        route()
    })

    window.onclick = function (event) {
        l("Window click", event.target)
        const dropdowns = document.querySelectorAll(".dropdown-menu");
        const username = $("username")
        if (event.target == username) {
            for (const dropdown of dropdowns) {
                dropdown.classList.toggle('show');
            }
        }
        else {
            for (const dropdown of dropdowns) {
                dropdown.classList.remove('show');
            }
        }
    }

    main = $$("main")

    initialiseTopMenu()

    await load_data()

    // Set top right username
    const div = document.getElementById("username")
    if (div && data && data.user && data.user)
        if (data.user.name) div.textContent = data.user.name
        else div.textContent = "No name"

    route()
}

async function load_data() {
    const url = "/api/data"
    try {
        const response = await fetch(url, { credentials: "include" })
        l(response)
        if (response.ok) {
            data = await response.json()
        }
        else if (response.status == 401) {
            throw Error("Unauthorised")
        }
        else {
            throw Error("Unable to fetch data")
        }

        if (data == null || data.users == null) throw Error("Broken response")

        // put the ids back into the objects.
        // they were removed for transmission
        for (const id of Object.keys(data.users)) {
            data.users[id].id = id
        }
    }
    catch (error) {
        console.error(error)
        const div = document.querySelector("footer")
        if (div) {
            div.textContent = `Unable to fetch data from server: ${error.message}`
            div.classList.add("error")
        }
    }
}

async function putJSON(url, options = {}): Promise<object | null> {
    return fetchJSON("PUT", url, options)
}

async function fetchJSON(method: string, url: string, options: RequestInit = {}): Promise<object | null> {
    options.method = method
    options.credentials = "include"
    const response = await fetch(url, options)
    if (response.ok) {
        return response.json()
    }
    return null
}

function route() {
    const hash = location.hash
    l("route", hash)

    if (data == null || data.user == null) {
        main.replaceChildren()
        addH1(main, "Redirecting")
        setTimeout(() => {
            location.href = "/login"
        }, (2000));
        return
    }

    const matches = hash.match(/(#[a-z]+)[/]?(.*)/i)
    if (matches) {
        l("Route matches", matches)
        let [, page, argument] = matches
        page = page.toLowerCase()
        argument = argument.toLowerCase()
        highlightActivePage(page)
        for (const [, pageHash, , handler] of page_handlers) {
            if (page == pageHash) {
                handler.call(this, argument)
                return
            }
        }
        home_page()
    }
    else {
        // when we set the hash that will trigger popstate to call us again
        const defaultPage = "connections"
        window.location.hash = `#${defaultPage}`
    }
}

function initialiseTopMenu() {
    const menuBar = $$(".top-menu-bar")
    const dropdownMenu = $$(".dropdown-menu")
    for (const [title, linkString, location] of page_handlers) {
        l(location, pageLocation.top)
        if (location == pageLocation.top) {
            const linkElement = addLink(menuBar, linkString)
            addDiv(linkElement, "top-menu", title).setAttribute("data", linkString)
        }
        else if (location == pageLocation.dropdown) {
            addLink(dropdownMenu, linkString, title)
        }
    }
    addLink(dropdownMenu, "/api/logout", "Logout")
}

function highlightActivePage(page: string) {
    const tabs = document.querySelectorAll(".top-menu")
    for (const tab of tabs) {
        if (tab.getAttribute("data") == page) {
            tab.classList.add("active")
        }
        else {
            tab.classList.remove("active")
        }
    }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////// PAGE HANDLERS
///////////////////////////////////////////////////////////////////////////////

page_handlers.push(["QR", "#qr", pageLocation.top, qr_page])

async function qr_page() {
    const qrLink = `${location.origin}/qr/${data.user.name}`
    main.replaceChildren()
    const container = addDiv(main, "canvas-container")
    if (data.user.name && !data.user.name.match(noobRegex)) {
        const canvas = addElement(container, "canvas")
        canvas.setAttribute("id", "qr")
        const div = addDiv(container, "qr-link")
        addHref(div, qrLink, qrLink)


        // @ts-expect-error "This expression is not constructable"
        new Qrious({
            element: document.getElementById("qr"),
            value: qrLink,
            size: 300,
            level: "H"
        });
    }
    else {
        addHref(container, "/choose-username", "You need to choose a username. Click Here.")

    }
}

///////////////////////////////////////////////////////////////////////////////

page_handlers.push(["Home", "#home", pageLocation.top, home_page])

async function home_page() {
    l("Home page")
    main.replaceChildren()
    addElement(main, "h1", null, "Home")
    addLink(main, `/connect/${data.user.name}`, "Shareable connection link")
    addDiv(main)
    addLink(main, `/login`, "Login")
    addDiv(main)
    addLink(main, `/connect-done`, "Connect Done")
    addDiv(main)
    addLink(main, `/login-done`, "Login Done")
    if (data.user.name == null || data.user.name.match(noobRegex)) addSetUserName(main)
}

function addSetUserName(parent: Element) {
    l(parent)
}

///////////////////////////////////////////////////////////////////////////////

page_handlers.push(["Connections", "#connections", pageLocation.top, connections_page])

function connections_page(argument: string | undefined) {
    const old = argument == "old"
    main.replaceChildren()

    let inboundDiv, outboundDiv, connectionsDiv
    const inboundContainer = addDiv(main, "inbound-connections-container")
    const connectionsContainer = addDiv(main, "connections-container")
    const outboundContainer = addDiv(main, "outbound-connections-container")

    let firstInbound = true
    let firstOutbound = true
    let firstConnection = true

    for (const connection of data.connections.sort(connectionsSorter)) {
        let div: Element | undefined
        if (old) {
            if (connection.state == connectionState.connected || connection.state == connectionState.new) continue
        }
        else {
            if (connection.state != connectionState.connected && connection.state != connectionState.new) continue
        }

        if (connection.from_timestamp && connection.to_timestamp) {
            if (firstConnection) {
                firstConnection = false
                addH1(connectionsContainer, old ? "Broken Connections" : "Connections")
                connectionsDiv = addDiv(connectionsContainer, "connections")
            }
            div = connectionsDiv
        }
        else if (connection.to_user_id == data.user.id) {
            if (firstInbound) {
                firstInbound = false
                addH1(inboundContainer, old ? "Requests Discarded" : "Requests Received")
                inboundDiv = addDiv(inboundContainer, "inbound-connections")
            }
            div = inboundDiv
        }
        else {
            if (firstOutbound) {
                firstOutbound = false
                addH1(outboundContainer, old ? "Requests Cancelled" : "Requests Sent")
                outboundDiv = addDiv(outboundContainer, "outbound-connections")
            }
            div = outboundDiv
        }
        if (div == null) {
            console.error(data.user.id, connection)
            throw Error("Bad connection")
        }
        const other_user_id = connection.from_user_id == data.user.id ? connection.to_user_id : connection.from_user_id
        const panel = addDiv(div, "user-list-panel")
        const userPanel = addUserPanel(panel, data.users[other_user_id])
        addConnectionNotePanel(userPanel, connection)
        addUserButtonPanel(userPanel, data.users[other_user_id])
    }

    if (old) {
        addHref(addParagraph(main), "#connections", "Click here to see normal connections")
    }
    else {
        addHref(addParagraph(main), "#connections/old", "Click here to see discarded, cancelled, or broken connections")
    }
}

function connectionsSorter(a, b) {
    // Handling cases where either from_timestamp or to_timestamp is null
    if (a.from_timestamp === null && b.from_timestamp !== null) {
        return 1;
    }
    if (a.from_timestamp !== null && b.from_timestamp === null) {
        return -1;
    }
    if (a.to_timestamp === null && b.to_timestamp !== null) {
        return 1;
    }
    if (a.to_timestamp !== null && b.to_timestamp === null) {
        return -1;
    }

    // Sorting based on the newest timestamp
    return Math.max(b.from_timestamp, b.to_timestamp) - Math.max(a.from_timestamp, a.to_timestamp);
}

async function handleConnectionClick(event: MouseEvent) {
    event.stopPropagation()
    l("handle click")
    const target = event.target as HTMLButtonElement
    if (!target) return false
    const attrib = target.getAttribute("data")
    if (!attrib) return false
    const [from_user_id, to_user_id, newState] = JSON.parse(attrib)
    l(from_user_id, to_user_id, newState)
    const connection = findConnection(from_user_id, to_user_id)
    for (const sibling of getSiblings(target)) {
        sibling.classList.remove("set")
    }
    target.classList.add("set")
    if (connection) {
        const result = await putJSON(`/api/setconnection/${connection.from_user_id}/${connection.to_user_id}/${newState}`) as Connection
        Object.assign(connection, result)
    }
    else {
        const result = await putJSON(`/api/setconnection/${from_user_id}/${to_user_id}/${newState}`) as Connection
        if (result) data.connections.push(result)
    }
    l("updated connection", connection)
    l(data.connections)
    route()
}

function getSiblings(element: HTMLElement | null): HTMLElement[] {
    if (!element) return []
    const parent = element.parentNode;
    if (!parent) return []
    return Array.from(parent.childNodes).filter(node => {
        return node.nodeType === Node.ELEMENT_NODE && node !== element;
    }) as HTMLElement[]
}

function findConnection(a_user_id: number, b_user_id: number) {
    // l(data.connections)
    for (const connection of data.connections) {
        if (connection.from_user_id == a_user_id && connection.to_user_id == b_user_id
            || connection.from_user_id == b_user_id && connection.to_user_id == a_user_id) return connection
    }
    return null
}

function addBigUserPanel(panel: Element, user: User) {
    panel = addUserPanel(panel, user)
    const connection = findConnection(data.user.id, user.id)
    if (connection) addConnectionNotePanel(panel, connection)
    addUserButtonPanel(panel, user, true)
}

function addUserPanel(panel: Element, user: User) {
    const userPanel = addDiv(panel, "user-panel")
    const userTopBar = addDiv(userPanel, "user-top-bar")
    const userName = addDiv(userTopBar, "user-name")
    if (user.name) {
        addHref(userName, `#profile/${user.name} `, user.name)
        addHref(userTopBar, `/api/switch/${user.id}`, "switch")
    }
    else {
        addText(userName, "No name yet")
    }
    addDiv(userPanel, "user-bio", user.bio)
    addDiv(userPanel, "user-interests", user.interests.map(x => data.interests[x]).join(", "))
    return userPanel
}

function addConnectionNotePanel(panel: Element, connection: null | Connection) {
    if (connection && connection.note) addDiv(panel, "connection-note").textContent = connection.note
}

function addUserButtonPanel(panel: Element, other_user: User, addBreak = false) {
    const buttonBar = addDiv(panel, "button-bar")
    const connection = findConnection(data.user.id, other_user.id)
    if (!connection) {
        if (data.user.id != other_user.id) addButton(buttonBar, "request", "Request Connection", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.new]))
    }
    else if (connection.from_timestamp && connection.to_timestamp) {
        // an existing connection
        if (connection.state == connectionState.broken) {
            addButton(buttonBar, "restore", "Restore Connection", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.connected]))
        }
        else if (addBreak) {
            addButton(buttonBar, "break", "Break Connection", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.broken]))
        }
    } else if (connection.from_user_id == data.user.id) {
        // outbound request
        if (connection.state == connectionState.new) {
            addButton(buttonBar, "cancel", "Cancel Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.cancelled]))
        }
        else {
            addButton(buttonBar, "cancel", "Revive Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.new]))
        }
    }
    else {
        // inbound request
        if (connection.state == connectionState.new) addButton(buttonBar, "discard", "Discard Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.discarded]))
        addButton(buttonBar, "accept", "Accept Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.connected]))
    }
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// ELEMENTS
///////////////////////////////////////////////////////////////////////////////


function addLink(parent: Element, link, text: string | null = null) {
    const element = addElement(parent, "a", null, text) as HTMLLinkElement
    element.href = link
    return element
}

function addElement(parent: Element, elementType: string, className: string | null = null, text: string | null = null) {
    const element = document.createElement(elementType)
    if (text) element.textContent = text
    if (className) element.classList.add(className)
    parent.appendChild(element)
    return element
}

type ClickEventHandler = (event: MouseEvent) => void

function addButton(parent: Element, className: string | null = null, text: string | null = null, handler: ClickEventHandler | null = null) {
    const element = addElement(parent, "button", className, text)
    if (handler) element.addEventListener("click", handler)
    return element
}

function addDiv(parent: Element, className: string | null = null, text: string | null = null) {
    const element = document.createElement("div")
    if (text) element.textContent = text
    if (className) element.classList.add(className)
    parent.appendChild(element)
    return element
}

function addH1(parent: Element, text: string | null = null) {
    const div = document.createElement("h1")
    if (text) div.textContent = text
    parent.appendChild(div)
    return div
}

function addHref(parent: Element, link: string | null = null, text: string | null) {
    const element = document.createElement("a")
    if (text) element.textContent = text
    if (link) element.href = link
    parent.appendChild(element)
    return element
}

function addParagraph(parent: Element, text: string | null = null) {
    const element = addElement(parent, "p", null, text)
    if (text) element.textContent = text
    parent.appendChild(element)
    return element
}

function addText(parent: Element, text: string | null) {
    if (text) {
        const element = document.createTextNode(text)
        parent.appendChild(element)
    }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////// USERS
///////////////////////////////////////////////////////////////////////////////

page_handlers.push(["Users", "#users", pageLocation.top, users_page])

function users_page() {
    main.replaceChildren()
    addParagraph(main, "")
    for (const user of Object.values(data.users)) {
        addBigUserPanel(main, user)
    }
}

///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////// PROFILE
///////////////////////////////////////////////////////////////////////////////

page_handlers.push(["Profile", "#profile", pageLocation.none, profile_page])

function profile_page(username) {
    main.replaceChildren()
    addParagraph(main, "")
    for (const user of Object.values(data.users)) {
        // l("username", username, "user.name", user.name)
        if (user.name.match(new RegExp(`${username}`, "i"))) {
            addBigUserPanel(main, user)
            break
        }
    }
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// SETTINGS
///////////////////////////////////////////////////////////////////////////////

page_handlers.push(["Settings", "#settings", pageLocation.dropdown, settings_page,])

function settings_page() {
    main.replaceChildren()
    addH1(main, "Settings")
    addText(main, JSON.stringify(data.user))
}
